<template>
  <div>
    <div class="container-fluid mt-4">
      <card class="mt-2">
        <loading-overlay :active.sync="loading" :is-full-page="true" />
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0 text-primary">Ganadores por fase</h3>
            </div>
            <div class="col-6 text-right">
              <!--  <base-button
                  :disabled="loading"
                  @click="openModal()"
                  type="info"
                  icon
                  size="sm"
                >
                  <span class="btn-inner--icon"
                    ><i class="fas fa-file-excel"></i
                  ></span>
                  <span class="btn-inner--text">Exportar</span>
                </base-button> -->
            </div>
          </div>
        </template>
        <div class="row justify-content-between">
          <div class="col">
            <div class="row px-2">
              <base-input label="Fase:" class="mb-0 pl-1">
                <el-select
                  v-if="!loading1"
                  v-model="fases.select"
                  filterable
                  placeholder="Elige una fase"
                >
                  <el-option
                    v-for="op in fases.multiple"
                    :key="op.value"
                    :label="op.label"
                    :value="op"
                  >
                  </el-option>
                </el-select>
                <div v-else style="height: 50px" class="vld-parent">
                  <loading-overlay
                    :height="30"
                    :active.sync="loading1"
                    :is-full-page="false"
                  />
                </div>
              </base-input>
              <base-input label="País:" class="mb-0 pl-1">
                <el-select
                  v-if="!loading1"
                  v-model="countries.select"
                  filterable
                  placeholder="Elige un país"
                >
                  <el-option
                    v-for="op in countries.multiple"
                    :key="op.value"
                    :label="op.label"
                    :value="op"
                  >
                  </el-option>
                </el-select>
                <div v-else style="height: 50px" class="vld-parent">
                  <loading-overlay
                    :height="30"
                    :active.sync="loading1"
                    :is-full-page="false"
                  />
                </div>
              </base-input>

              <base-input label=" " class="mb-0 pt-2">
                <base-button
                  type="button"
                  class="btn btn-primary"
                  native-type="submit"
                  :disabled="validate_sorteo"
                  @click="getWinners()"
                >
                  Ver
                </base-button>
              </base-input>
            </div>
          </div>
        </div>

        <div class="container-flid">
          <div class="row">
            <div class="mx-auto">
              <h2 class="text-primary text-center">Ganadores</h2>
            </div>
          </div>

          <div class="card">
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="winners"
            >
              <div slot="empty" v-if="winners.length == 0 && !loading">
                <span>No se ha encontrado información</span>
              </div>
              <el-table-column
                min-width="200px"
                label="Nombre"
                prop="name"
                
              />
              <el-table-column
                min-width="200px"
                label="Promoción"
                prop="promotion"
                
              />
            </el-table>
          </div>
        </div>
      </card>

      <!-- Modal para mostrar los datos del ususario ganador -->
      <modal :show.sync="modal">
        <h6 slot="header" class="modal-title">Información de contacto</h6>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p><strong>Nombre:</strong> {{ usuario.name }}</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p><strong>Email:</strong> {{ usuario.email }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p><strong>Identificación:</strong> {{ usuario.identification }}</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p><strong>Teléfono:</strong> {{ usuario.tel }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p><strong>Dirección:</strong> {{ usuario.address }}</p>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
/* import { BasePagination } from "@/components/"; */
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import { Modal } from "@/components";
/* import flatPicker from "vue-flatpickr-component"; */
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  Table,
  TableColumn,
  Tooltip,
  Select,
  Option,
} from "element-ui";

export default {
  layout: "DashboardLayout",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },

  data() {
    return {
      config: {
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      loading: false,
      loading1: false,
      countries: {select: '', multiple: []},
      cutoffdates: [],
      cutoff_date: null,
      country: null,
      winners: [],
      winner: [],
      promociones: {select: '', multiple: []},
      fases: {select: '', multiple: [{"value": 1, "label":"1"},{"value": 2, "label":"2"},{"value": 3, "label":"3"}]},
      usuario: {
        name: "",
        email: "",
        identification: "",
        tel: "",
        address: "",
      },
      modal: false,
    };
  },

  computed: {
    validate_sorteo: function () {
      //console.log("Fases.select: ", this.fases.select);
      return !this.fases.select || !this.countries.select;
    },
    validate: function () {
      return !this.winners.length > 0;
    },
  },
  created() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      let aux = {};

      if (this.countries.select !== '') {
        aux = this.countries.select; 
      }

      this.countries = {select: '', multiple: []};
      await this.$store.dispatch("countries/list").then(() => {
        //const self = this;
        let HelperContries = this.$store.getters["countries/list"]

        for(let i = 0; i < HelperContries.length; i++){
          let Nuevo = {"value": HelperContries[i].country_id, "label": HelperContries[i].name}
          this.countries.multiple.push(Nuevo)
          //self.countries.multiple.push(Nuevo)
        }

        /*if (this.query === null) {
          this.countries.select = {value: 1, label: "Guatemala"};
        } else {
          this.countries.select = aux;
          //this.countries.select = {value: this.query.country_id, label: this.query.country_name};
        }*/

        //this.countries_export.multiple.push({"value":-1,"label":"Todos"});
        //this.countries_export.select = {value:-1,label:"Todos"};
        this.loading1 = false;
        //this.getList();
      });
    },
    openModal(row) {
      this.usuario.name = row.name;
      this.usuario.email = row.email;
      this.usuario.tel = row.tel;
      this.usuario.identification = row.identification;
      this.usuario.address = row.address;
      this.modal = true;
    },
    async getCutOffDate() {
      await this.$store.dispatch("cutOffDate/list").then(() => {
        this.cutoffdates = this.$store.getters["cutOffDate/list"];
      });
      this.loading1 = false;
    },

    async getWinners() {
      this.loading = true;
      try {
        const params = {
          "phase": this.fases.select.value,
          "country": this.countries.select.value,
        };

        await this.$store.dispatch("tombola/viewWinners", params).then(() => {
          this.winners = this.$store.getters["tombola/getWinners"];
          
          if (this.winners.length === 0 || this.winners.length === undefined) {
            this.winners = [];

            swal.fire({
              title: `¡Atención!`,
              text: "No hay ningún ganador para este país o fase.",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              icon: "warning",
            });
          }
        });
        this.loading = false;
      } catch (e) {
        //console.log("error: ", e);
        /*this.sendError("View winners users ", error.response, {
          country: this.country,
          cutOffDate: this.cutoff_date,
          winners: this.winners,
        });*/

        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Lo sentimos, a sucedido un error, inténtalo más tarde.`,
        });
      }
    },
    async getUserComplete() {
      await this.$store
        .dispatch("users/getComplete")
        .then(() => {
          const user = this.$store.getters["users/user_complete"];
          this.country = user.country;
        })
        .catch((error) => {
          this.sendError(
            "Get complete user / View winners users ",
            error,
            user
          );
        });
    },

    async sendError(metodo, error, data) {
      await this.$store.dispatch("errors/sendError", {
        plataforma: "Tombola del éxito / Clavulin",
        metodo: metodo,
        error: JSON.stringify(error),
        data: JSON.stringify(data),
      });
    },
  },
};
</script>
<style scoped>
/* Escritorio extra grande grande */
/* @media (min-width: 1296px) {
  .tam-img {
    width: 200px !important;
  }
}
 */
@media (min-width: 1200px) {
  .tam-img {
    width: 200px !important;
  }
}

/* Escritorio grande */
/* @media (max-width:  1200px) and (min-width: 1295px) {
  .tam-img {
    width: 200px !important;
  }
} */
/* Escritorio mediano o tablet horizontal */
@media (max-width: 1199px) and (min-width: 992px) {
  .tam-img {
    width: 200px !important;
  }
}

/* Escritorio pequeño o tablet vertical */
@media (max-width: 991px) and (min-width: 500px) {
  .tam-img {
    width: 200px !important;
  }
}

/* Movil */
@media (max-width: 499px) {
  .tam-img {
    width: 200px !important;
  }

  #tx-trasparente .form-group label {
    opacity: 0 !important;
  }
}
</style>
